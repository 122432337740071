import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Commitment from '@widgets/Commitment'

const TermsOfUse = props => (
  <Layout {...props} locale='zh-CN'>
    <Seo title='使用条款' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header='使用条款'
          subheader='为了更好地为您提供服务，在您开始使用本网站及服务之前，请务必认真阅读并充分理解本协议。如果您对本协议的任一条款表示异议，或者无法准确理解本协议任何内容，请停止访问和/或使用本服务。若您使用网站及相关服务，则视为您已充分理解本协议并承诺接受协议的约束。'
        />
        <h2>知识产权条例</h2>
        <b>您应理解并同意：</b>
        <br />
        <li>
          本网站中所涉及的defi.to图形、文字或其组成，以及其他defi.to标志及产品、服务名称，均为本网站标识。未经本网站事先书面同意，您不得将本网站标识以任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理本网站标识的行为。若您由于非法使用本网站标识给本网站或他人造成损失，由您承担相关法律责任。
        </li>

        <li>
          本网站的所有内容和信息，包括文字、图片、音频、视频及其他内容，均受到著作权法、商标法、专利法以及其他相关法律的保护。未经网站书面授权许可，您不得自行或许可第三方进行复制、转载、传播、修改、售卖、衍生其他作品或者从事其他侵犯本网站知识产权的行为。
        </li>
        <h2>第三方网站条例</h2>
        <p>
          本网站可能包含由第三方维护的其他网站链接。这些链接仅作为一种方便用户的内容补充，并不代表本网站立场，也并不意味着本网站对第三方网站的认可或与第三方网站的联系。
        </p>
        <h2>协议修改条例</h2>
        <b>您应理解并同意：</b>
        <br />
        <li>
          本网站保留在任何时候修改或补充本协议中任何条款的权利，您有责任及时审查本网站及本协议服务条款的变更。
        </li>
        <li>
          新协议一经公布即代替原协议。若您继续使用本网站服务，则视为完全同意该协议；若您不接受更新后的协议，应立即停止使用本网站服务。
        </li>
        <h2>终止使用条例</h2>
        <b>您应理解并同意：</b>
        <br />
        <li>
          本网站及相关服务是按照现有技术和条件所能达到的现状提供的，网站会尽最大努力向您提供服务，确保服务的连贯性和安全性。但网站不能随时预见和防范技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵及其他各种安全问题的侵扰等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
        </li>
        <li>
          为了服务整体运营的需要，本网站有权在公告通知后修改、中断、中止或终止相关服务，而无须向用户负责或承担任何赔偿责任。
        </li>
        <li>
          为了更好地为您提供服务，营造良好网络生态，保障用户、投资者和其他组织的合法权益，维护国家安全和公共利益，维护正常的经济金融秩序，防范金融风险，打击金融违法犯罪活动，本网站有权立即终止或暂停您对本网站的全部或部分访问，而无须向用户负责或承担任何赔偿责任。
        </li>
        <h2>免责声明</h2>
        <p>
          本网站作为信息内容服务网站，仅提供基于数据挖掘技术的内容信息，我们只编译信息，不提供任何财务或投资建议。对于一切因使用本网站而可能遭致的意外、疏忽、侵权及其已经或可能造成的损失，本网站对其概不负责，亦不承担任何法律责任。您在使用本网站服务时应保持理性，强化内容识别能力和风险防范意识，谨防上当受骗，及时举报相关违法违规信息内容。
        </p>
        <Divider />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
      </Sidebar>
    </Stack>
  </Layout>
)

export default TermsOfUse
