import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='关于defi.to' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        defi.to，是一个DeFi（去中心化金融）爱好者聚集地。凭借掌握全球一线DeFi项目发展状况及数据资源优势，defi.to
        通过不断输出DeFi概念解读、价值DeFi项目研究、热门DeFi项目图文教程等内容，帮助更多用户走进DeFi、了解DeFi、玩转DeFi，并逐渐成为DeFi爱好者传递海内外DeFi文化、连接项目与用户之间的首选桥梁。
      </Text>
    </Card>
  </Section>
)

export default Commitment
